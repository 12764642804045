import React from "react"
import CookiesPage from "../../components/pages/CookiesPolicy"
import { graphql } from "gatsby"

const lang = "ca"

const CookiesPolicy = ({ data, pageContext }) => {
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url_ca,
    titleSeo: seoDataJson.title_ca,
    description: seoDataJson.description_ca,
    alternateLanguage: seoDataJson.alternateLanguage_ca,
    alternateUrl: seoDataJson.alternateUrl_ca,
  }

  return <CookiesPage lang={lang} seoData={seoData} pageContext={pageContext} />
}

export const pageQuery = graphql`
  query cookiesCaQuery {
    seoDataJson(name: { eq: "cookies" }) {
      url_ca
      title_ca
      description_ca
      alternateLanguage_ca
      alternateUrl_ca
    }
  }
`

export default CookiesPolicy
