import React from "react"
import styles from "./index.module.scss"
import tt from "../../helpers/translation"

const Switch = ({ handleToggle, isOn, lan = "en" }) => {
  console.log(isOn)
  return (
    <div className={styles.wrapper}>
      <div className={styles.switch_wrapper} onClick={handleToggle}>
        <input
          checked={isOn}
          onChange={handleToggle}
          className={styles.switch_checkbox}
          id={styles.switch_new}
          type="checkbox"
        />
        <label
          className={isOn ? styles.switch_label : styles.switch_label_disabled}
          htmlFor={`react-switch-new`}
        >
          <span className={styles[`switch_button`]} />
        </label>
      </div>
      {isOn ? (
        <span className={styles[`activate_label`]}>
          {tt("Cookies enabled", lan)}
        </span>
      ) : (
        <span className={styles[`activate_label`]}>
          {tt("Cookies disabled", lan)}
        </span>
      )}
    </div>
  )
}

export default Switch
