import React, { useCallback, useState } from "react"
import Layout from "../../Layout/Layout"
import Switch from "../../switch/switch"
import styles from "../../../styles/privacy.module.scss"
import tt from "../../../helpers/translation"
import Cookies from "js-cookie"

const CookiesPolicy = ({ lang, seoData, pageContext }) => {
  const [cookieAck, _setCookieAck] = useState(Cookies.get("cookie-ack"))
  const setCookieAck = useCallback(
    value => {
      Cookies.set("cookie-ack", value, { expires: 365 })
      _setCookieAck(value)
    },
    [_setCookieAck]
  )

  const accept = () => {
    setCookieAck("1")
    typeof loadGTM === "function" && loadGTM()
    if (typeof window !== "undefined") {
      window["ga-disable-UA-115165908-1"] = false
    }
  }

  const deny = () => {
    Object.keys(Cookies.get()).forEach(cookieName => {
      if (cookieName !== "cookie-ack") {
        Cookies.remove(cookieName)
      }
    })
    setCookieAck("0")
    if (typeof window !== "undefined") {
      window["ga-disable-UA-115165908-1"] = true
    }
  }

  const switchCookieAck = cookieAck => {
    if (cookieAck === "1") {
      deny()
    } else {
      accept()
    }
  }

  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === "COOKIES"
  })

  return (
    <Layout
      lan={lang}
      contrast={true}
      seoData={seoData}
      urls={resultUrls}
      noFooter
      location="cookies-policy"
    >
      <div className={styles.container}>
        <div>
          <div className={`${styles.text_container} ${styles.title}`}>
            {/* <h1>COOKIES</h1> */}
            <div className={styles.subtitle_p_container}>
              <p className={styles.subtitle}>COOKIES</p>
            </div>
          </div>

          <div className={styles.text_container}>
            <h3>{tt("DEFINITION AND FUNCTION OF COOKIES", lang)}</h3>
            <p className={styles.description}>
              {tt(
                "A cookie is a file which is downloaded in your computer when you access certain websites.",
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                `Cookies, among other things, allow a website to store and recover information about the navigation habits of a user or its computer and, depending on the information they store and the way of using the equipment, can recognise the user.`,
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                `This website, like the majority of Internet sites, uses cookies in order to improve and optimize user experience. Following, you will find detailed information about the typology of cookies that this website uses, how to disable them in your browser and how to specifically block the usage of cookies installed by third parties.`,
                lang
              )}
            </p>
          </div>
          <div className={styles.text_container}>
            <h3>{tt(`USED COOKIES IN THIS SITE`, lang)}</h3>
            <p className={styles.description}>
              {tt(
                `In this section we will briefly describe you which cookies we use and also give you the opportunity to disable them if you found them inconvenient, just by using the switch below.`,
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                `Cookies can be used either in a temporary (session cookies) or permanent way. Permanent cookies are stored in your computer or mobile device for a specific period of time, never greater than 24 months.`,
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                `This site specifically uses cookies to gather navigation information, tracking the sections you visit and the time you spend in each section, for instance, for analytical purposes only. It does that through the usage of cookies provided by Google `,
                lang
              )}
              <strong>{tt(`(_ga and _gid). `, lang)}</strong>
              {tt(
                `If you wish to turn this tracking off, you might switch off below.`,
                lang
              )}
              {tt(
                ` For more information regarding Google Analytics cookies deactivation click `,
                lang
              )}
              <a
                target="blank"
                href="https://tools.google.com/dlpage/gaoptout?hl=en"
              >
                <strong>{tt(`here.`, lang)}</strong>
              </a>
            </p>
            <Switch
              isOn={cookieAck === "1"}
              lan={lang}
              handleToggle={() => switchCookieAck(cookieAck)}
            />
          </div>
          <div className={styles.text_container}>
            <h3>{tt(`WHAT HAPPENS IF COOKIES ARE DISABLED`, lang)}</h3>
            <p className={styles.description}>
              {tt(
                `This would not allow us to save and track information about your visit, which sections have been visited, how long you have been in our website or if you have interacted with us through any of our forms. In any case you will still be able to navigate through our website as it will keep working optimally.`,
                lang
              )}
            </p>
            <h4>{tt(`HOW TO DISABLE OR DELETE COOKIES`, lang)}</h4>
            <p className={styles.description}>
              {tt(
                `You can deny the usage of cookies through our website options or by accessing the settings of your browser in order to modify and/or delete the installation of cookies.`,
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                `In any moment you can allow, read about, block or deny the cookies installed in your computer through the settings in the browser you use.`,
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                `Depending on which browser you use, you may find information on how to do it by following the indications of the following links:`,
                lang
              )}
            </p>
            <p className={styles.description}>
              <b>Internet Explorer: </b>
              <a
                target="_blangk"
                href="https://privacy.microsoft.com/es-es/privacystatement"
              >
                https://privacy.microsoft.com/es-es/privacystatement
              </a>
              <br></br>
              <b>Safari: </b>
              <a
                target="_blangk"
                href="https://www.apple.com/legal/privacy/es/cookies/ https://support.apple.com/es-es/HT201265"
              >
                https://www.apple.com/legal/privacy/es/cookies/
                https://support.apple.com/es-es/HT201265
              </a>
              <br></br>
              <b>Google Chrome: </b>
              <a
                target="_blangk"
                href="https://support.google.com/chrome/answer/95647?hl=es&hlrm=en"
              >
                https://support.google.com/chrome/answer/95647?hl=es&hlrm=en
              </a>
              <br></br>
              <b>Firefox: </b>
              <a
                target="_blangk"
                href="http://support.mozilla.org/es/kb/Borrar%20cookies"
              >
                http://support.mozilla.org/es/kb/Borrar%20cookies
              </a>
              <br></br>
              <b>Opera: </b>
              <a target="_blangk" href="https://www.opera.com/es/privacy">
                https://www.opera.com/es/privacy
              </a>
              <br></br>
              <b>Android (Google): </b>
              <a
                target="_blangk"
                href="https://www.google.com/intl/es/policies/privacy/"
              >
                https://www.google.com/intl/es/policies/privacy/
              </a>
              <br></br>
              <b>Windows Phone (Microsoft): </b>
              <a
                target="_blangk"
                href="https://privacy.microsoft.com/es-es/privacystatement"
              >
                https://privacy.microsoft.com/es-es/privacystatement
              </a>
              <br></br>
              <b>Blackberry: </b>
              <a
                target="_blangk"
                href="http://global.blackberry.com/es/legal/privacy-policy.html"
              >
                http://global.blackberry.com/es/legal/privacy-policy.html
              </a>
            </p>
            <p className={styles.description}>
              {tt(
                `You may also eliminate easily the cookies from your computer or mobile device through the browser.`,
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                `In order to get further indications on how to manage and eliminate cookies, you may click on the Help tab of your browser. You will be able to disable the cookies or receive a notification each time a new cookie is installed in your computer or mobile device.`,
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(
                `There are a few online tools that allow the user to identify the cookies used in each site they visit and manage their deactivation.`,
                lang
              )}
            </p>
          </div>
          <div className={styles.text_container}>
            <h3>{tt(`UPDATE OF OUR COOKIE POLICY`, lang)}</h3>
            <p className={styles.description}>
              {tt(
                `It is possible that we update our cookie policy every now and then. For this reason we kindly recommend you to review our policy each time you might check our website in order to keep informed on how and why we use cookies. We also advise you to bear in mind that browsers might as well change their policies as well as the location of the information.`,
                lang
              )}
            </p>
            <p className={styles.description}>
              {tt(`Cookie policy was last updated on 29/01/2020.`, lang)}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CookiesPolicy
